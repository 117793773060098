import React from "react";
import {
  FocusScope,
  OverlayContainer,
  useOverlay,
  useModal,
  useDialog,
} from "react-aria";
import { motion, AnimatePresence } from "framer-motion";
import { FaSquareXing } from "react-icons/fa6";

function SlideOverContent(props) {
  const { onClose, children, title } = props;

  const ref = React.useRef();
  const { overlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal(props, ref);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div className="tw-fixed tw-inset-0 tw-overflow-hidden tw-mt-16">
      <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
        <motion.div
          className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
          aria-hidden="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        ></motion.div>
        <motion.section
          className="tw-absolute tw-inset-y-0 tw-right-0 tw-pl-10 tw-max-w-full tw-flex"
          aria-labelledby="tw-slide-over-heading"
          ref={ref}
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
        >
          <FocusScope contain restoreFocus autoFocus>
            <div className="tw-relative tw-w-screen tw-max-w-md tw-mt-4">
              <div className="tw-absolute tw-top-0 tw-right-0 tw-mr-8 tw-pt-4 tw-pr-2 tw-flex tw-sm:-ml-10 tw-sm:pr-4">
                <button
                  onClick={onClose}
                  className="tw-rounded-md tw-text-gray-300 tw-hover:text-white tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-white "
                >
                  <span className="tw-sr-only">Close panel</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1505 8.27961L10.2761 8.39052L16 14.1133L21.7239 8.39052C22.2446 7.86983 23.0888 7.86983 23.6095 8.39052C24.0901 8.87117 24.1271 9.62748 23.7204 10.1505L23.6095 10.2761L17.8867 16L23.6095 21.7239C24.1302 22.2446 24.1302 23.0888 23.6095 23.6095C23.1288 24.0901 22.3725 24.1271 21.8495 23.7204L21.7239 23.6095L16 17.8867L10.2761 23.6095C9.75544 24.1302 8.91122 24.1302 8.39052 23.6095C7.90988 23.1288 7.87291 22.3725 8.27961 21.8495L8.39052 21.7239L14.1133 16L8.39052 10.2761C7.86983 9.75544 7.86983 8.91122 8.39052 8.39052C8.87117 7.90988 9.62748 7.87291 10.1505 8.27961Z"
                      fill="#4B5563"
                    />
                  </svg>
                </button>
              </div>
              <div className="tw-h-full tw-flex tw-flex-col tw-py-6 tw-bg-white tw-shadow-xl tw-overflow-y-scroll ">
                <div className="tw-px-4 tw-sm:px-6 ">
                  <h2
                    id="tw-slide-over-heading"
                    className="tw-text-lg tw-font-medium tw-text-gray-900 "
                    {...titleProps}
                  >
                    {title}
                  </h2>
                </div>
                <div className="tw-mt-6 tw-relative tw-flex-1 tw-px-4 tw-sm:px-6">
                  {children}
                </div>
              </div>
            </div>
          </FocusScope>
        </motion.section>
      </div>
    </div>
  );
}

function SlideOver({ isOpen, onClose, title, children }) {
  return (
    <OverlayContainer>
      <AnimatePresence>
        {isOpen && (
          <SlideOverContent
            title={title}
            onClose={onClose}
            isOpen={isOpen}
            isDismissable
          >
            {children}
          </SlideOverContent>
        )}
      </AnimatePresence>
    </OverlayContainer>
  );
}

export default SlideOver;
