import React, { useEffect, useState } from "react";
import world from "../../../../images/HomePageImage/homePageHeroSection/world.png";
import locationMarker from "../../../../images/map/location_marker.png";
import MapChart from "./MapChart";
import "./WorldWideMap.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const WorldWideMap = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);
  return (
    <div className="tw-max-w-[1440px] md:tw-mx-auto tw-p-4 tw-mt-10">
      <div className="tw-w-full">
        <div className="">
          <div className=" tw-flex tw-flex-col tw-justify-center tw-items-center">
            <img className="tw-w-16 tw-h-16" src={world} alt="logo" />
          </div>
          <div className="tw-p-4">
            <h3 className="tw-text-center tw-text-gray-900 tw-text-3xl md:tw-text-4xl tw-font-bold">
              <span className="tw-text-[#6F39B7]">{t("Global")}</span>{" "}
              {t("Mojaru’s Presence")}
            </h3>
            <p className="tw-text-center tw-text-gray-600 tw-mt-6">
              {t(
                "Students from over 57 countries have experienced Mojaru’s exceptional learning journey"
              )}
            </p>
          </div>
        </div>
        {/* <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-6 xl:tw-grid-cols-6 tw-gap-4 tw-mx-10"> */}
        <div className="tw-flex tw-max-w-[1440px] tw-justify-center tw-overflow-x-scroll tw-gap-4 custom-scrollbar">
          <div className="locationTag">
            <div className="tw-text-center tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("Australia")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="tw-text-center  tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("Asia")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="tw-text-center  tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("Africa")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="tw-text-center  tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("America")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="tw-text-center  tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("Antarctica")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="tw-text-center  tw-py-3 tw-bg-purple-50 tw-rounded-[40px] tw-my-4">
              <div className="tw-flex tw-justify-center tw-content-center tw-mx-4">
                <img
                  className="tw-w-5 tw-h-6"
                  src={locationMarker}
                  alt="icon"
                />
                <div className="tw-ml-2 tw-inline-block tw-my-[1px]">
                  <p className="tw-font-[400] tw-text-[18px] tw-leading-[20px] tw-text-[#6D758F]">
                    {t("Europe")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Map design  */}
        <div>
          <MapChart />
        </div>
      </div>
    </div>
  );
};

export default WorldWideMap;
