import abacus from "../images/leadCollectImage/abacus.png";
import adorshoLipi from "../images/leadCollectImage/adorsholipi.png";
import programming from "../images/leadCollectImage/programming.png";
import mojarGonit from "../images/leadCollectImage/mojar-gonit.png";
import gonitOlypiad from "../images/leadCollectImage/gonit-olympiad.png";
import cadetPre from "../images/leadCollectImage/cadet-pre.png";
import cadetMission from "../images/leadCollectImage/cadet-mission.png";
import smartEnglish from "../images/leadCollectImage/smart-english.png";


import fb from '../images/footerSocial/facebook.svg';
import whatsapp from '../images/footerSocial/whatsapp.svg';
import X from '../images/footerSocial/SocialX.png';
import tiktok from '../images/footerSocial/tiktok.svg';
import linkedin from '../images/footerSocial/linkdin.svg';
import youtube from '../images/footerSocial/youtube.svg';
import instagram from '../images/footerSocial/insta.svg';


export const steps = [
    {
        name: 'তোমার পছন্দের কোর্স সিলেক্ট করো',
        courseInfo: [
            {
                courseImage: abacus,
                courseName: "অ্যাবাকাস মাইন্ড ম্যাথ",
            },
            {
                courseImage: adorshoLipi,
                courseName: "আদর্শলিপি",
            },
            {
                courseImage: programming,
                courseName: "প্রোগ্রামিং ফর কিডস",
            },
            {
                courseImage: mojarGonit,
                courseName: "মজার গণিত",
            },
            {
                courseImage: gonitOlypiad,
                courseName: "গণিত অলিম্পিয়াড",
            },
            {
                courseImage: mojarGonit,
                courseName: "একাডেমিক প্রস্তুতি- ৭ম শ্রেণি",
            },
            {
                courseImage: cadetPre,
                courseName: "ক্যাডেট প্রি-প্রিপারেশন",
            },
            {
                courseImage: cadetMission,
                courseName: "মিশন ক্যাডেট - ২৬",
            },
            {
                courseImage: smartEnglish,
                courseName: "স্মার্ট ইংলিশ",
            },

        ],

        description: 'Vitae sed mi luctus laoreet.',
        href: '#',
        status: 'complete'
    },
    {
        name: 'তোমার ক্লাস',
        classes: [
            {
                className: "প্রি-স্কুল",
                bgColor: "tw-bg-[#532D80]",
            },
            {
                className: "প্রথম শ্রেণি",
                bgColor: "tw-bg-[#14B8A6]",
            },
            {
                className: "দ্বিতীয় শ্রেণি",
                bgColor: "tw-bg-[#F43F5E]",
            },
            {
                className: "তৃতীয় শ্রেণি",
                bgColor: "tw-bg-[#06B6D4]",
            },
            {
                className: "চতুর্থ শ্রেণি",
                bgColor: "tw-bg-[#6366F1]",
            },
            {
                className: "পঞ্চম শ্রেণি",
                bgColor: "tw-bg-[#F59E0B]",
            },
            {
                className: "ষষ্ঠ শ্রেণি",
                bgColor: "tw-bg-[#8B5CF6]",
            },
            {
                className: "সপ্তম শ্রেণি",
                bgColor: "tw-bg-[#EC4899]",
            },
            {
                className: "অষ্টম শ্রেণি",
                bgColor: "tw-bg-[#F97316]",
            },
        ],
        description: 'Cursus semper viverra facilisis et et some more.',
        href: '#',
        status: 'current',
    },
    {
        name: 'তোমার তথ্য দাও',
        description: '',
        href: '#', status: 'upcoming'
    },
]


 export const socialLinks = {
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/mojaru.tech',
            icon: fb,
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/mojaru.tech/',
            icon: instagram,
        },
        {
            name: 'Whatsapp',
            href: 'https://api.whatsapp.com/send/?phone=8801610075103&text&type=phone_number&app_absent=0',
            icon: whatsapp,
        },
        {
            name: 'youtube',
            href: 'https://www.youtube.com/@Mojarutech',
            icon: youtube,
        },
        {
            name: 'tiktok',
            href: 'https://www.tiktok.com/@mojaru.tech',
            icon: tiktok,
        },
        {
            name: 'linkedin',
            href: 'https://www.linkedin.com/company/mojarutech/mycompany/',
            icon: linkedin,
        }
    ],
};

export const timeSlots = [
    {
        morningTimes: [
            "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM",
            "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM",
            "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM"
        ]
    },
    {
        afterNoonTimes: [
            "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM",
            "01:00 PM", "02:00 PM", "02:15 PM", "02:30 PM",
            "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM",
            "03:45 PM"
        ]
    },
    {
        eveningTimes: [
            "04:00 PM", "04:15 PM", "04:30 PM", "04:45 PM",
            "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM",
            "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM"
        ]
    },
    {
        nightTimes: [
            "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM",
            "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM",
            "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM"
        ]
    }
];


