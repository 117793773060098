import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentMethod from "./PaymentMethod";
import { toast } from "react-toastify";
import { FaLock, FaPlayCircle } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { CiCircleMinus } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";

const PaymentDetails = () => {
  const navigate = useNavigate();

  // Handle Payment Previous Functions will be there
  const selectedPayment = JSON.parse(localStorage.getItem("selected_payment"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const selectedBatch = JSON.parse(localStorage.getItem("selected_batch"));
  const courseInformation = JSON.parse(localStorage.getItem("course_info"));
  const couponInfo = JSON.parse(localStorage.getItem("couponInfo"));
  const courseFullImage =
    courseInformation?.basePath + courseInformation?.courseImagePath;
  const discountPrice = couponInfo.discountPrice;
  const finalPrice = selectedPayment?.course_final_price;
  const totalPrice = finalPrice - discountPrice;

  //   ---------------------------------------------
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isCheckTandC, setIsCheckTandC] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);

  //Preparing data for api
  const data = {
    payment_segment_id: selectedPayment?.id || "",
    payment_method_id: selectedMethod || "",
    user_id: userId,
    id: userId,
    course_id: selectedBatch.course_id,
    batch_id: selectedBatch.id,
    coupon_code: selectedPayment?.coupon_code || "",
    success_url: `${process.env.REACT_APP_BASE_URL}/payment-success`, // Replace with your success page URL
    fail_url: `${process.env.REACT_APP_BASE_URL}/payment-failed`, // Replace with your failed page URL
  };

  //---------All Use Effects Start----
  // Call the get api of the payment methods
  useEffect(() => {
    if (selectedPayment)
      fetch(`${process.env.REACT_APP_BASE_URL}api/v2/payment-methods`) // Replace with your actual API endpoint
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setPaymentMethods(data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching payment methods:", error);
        });
  }, []);

  //---------All Use Effects End----

  //---------All Handlers start-----------
  //Teams and conditions handler
  const handleTandC = (e) => {
    setIsCheckTandC((prevState) => !prevState);
  };

  //   Payment Button handler
  const handlePaymentSubmit = () => {
    if (
      !selectedPayment || // If selectedPayment is null, undefined, or empty
      selectedPayment.finalPrice === "" || // If finalPrice is an empty string
      selectedPayment.finalPrice === 0 // If finalPrice is 0
    ) {
      fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-subscription`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message_type === "success") {
            toast.success(data.message);
            navigate("/my-course");
          } else {
            toast.error(data.message);
          }
        });
    } else {
      if (!selectedMethod) {
        toast.error("Please select a payment method.");
        return;
      }
      fetch(
        `${process.env.REACT_APP_BASE_URL_PAYMENT}student/course-payment-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message_type === "success") {
            window.location.href = data.data.payment_url; // Redirect to third-party payment page
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error submitting payment:", error);
        });
    }
  };

  //---------All Handlers End-----------

  return (
    <>
      <div className="md:tw-flex tw-justify-between tw-px-12 tw-mt-10">
        {/* কোর্স অর্ডার Card  */}
        <div className="md:tw-w-[58%] tw-mr-[2%] tw-w-full">
          <p className="tw-text-lg tw-font-bold tw-mt-3 tw-my-4">
            কোর্স অর্ডার{" "}
          </p>
          <div className="tw-border tw-rounded-2xl">
            <div className="md:tw-flex tw-px-8 tw-pt-8">
              <img
                className="tw-max-w-[224px] tw-max-h-[128px] tw-rounded-lg tw-mr-8"
                src={courseFullImage}
                alt="image"
              />
              <div className="">
                <span className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-yellow-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-yellow-800 tw-ring-1 tw-ring-inset tw-ring-yellow-600/20">
                  {selectedBatch.batch_name}
                </span>
                <p className="tw-mt-2">{courseInformation?.courseNames}</p>
                <div className="tw-flex tw-mt-2 tw-items-center ">
                  <FaPlayCircle className="tw-text-gray-500 " />
                  <p className="tw-ml-2">
                    {courseInformation?.courseClasses} টি লাইভ ক্লাস{" "}
                  </p>
                </div>
                <div className="tw-flex tw-mt-2 tw-items-center">
                  <GiNetworkBars className="tw-text-gray-500" />
                  <p className="tw-ml-2">৪টি লেভেল</p>
                </div>
              </div>
            </div>
            {selectedPayment?.course_final_price ? (
              <div>
                <div className="tw-flex tw-justify-between tw-mt-8 tw-px-8">
                  <div>
                    <p>কোর্সের ফি </p>
                    <div className="tw-flex">
                      <p className="tw-mt-4">ডিসকাউন্ট</p>
                      <div className="tw-flex tw-mt-4 tw-items-center">
                        <CiCircleMinus className=" tw-ml-2 tw-text-[#EF4444]" />
                        <p className="tw-ml-2 tw-font-bold tw-text-[#374151]">
                          {selectedPayment?.coupon_code}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>৳ {finalPrice}</p>
                    <p className="tw-text-[#EF4444] tw-mt-4">
                      -৳ {couponInfo.discountPrice}
                    </p>
                  </div>
                </div>
                <div className="tw-relative tw-mt-6">
                  <div
                    className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                    aria-hidden="true"
                  >
                    <div className="tw-w-full tw-border-t twborder-gray-300" />
                  </div>
                </div>
                <div className="tw-flex tw-justify-between tw-px-6 tw-py-4 tw-bg-gray-100 tw-rounded-bl-2xl tw-rounded-br-2xl">
                  <p>সর্বমোট (ভ্যাট সহ)</p>
                  <p>৳ {totalPrice}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* পেমেন্ট মেথড সিলেক্ট করো */}
        <div className="md:tw-w-[38%] tw-w-full ">
          <div>
            <div className="md:tw-flex tw-justify-between tw-items-center">
              <p className="tw-text-lg tw-font-bold tw-mt-3 tw-mb-2">
                পেমেন্ট মেথড সিলেক্ট করো
              </p>
              <div className="tw-flex tw-items-center tw-bg-green-50 tw-px-2 tw-py-2 tw-rounded-2xl tw-mb-3">
                <FaLock className=" tw-text-emerald-400" />
                <p className="tw-text-[#15803D] tw-ml-2">
                  সম্পূর্ণ নিরাপদ পেমেন্ট
                </p>
              </div>
            </div>

            <div className="tw-mt-0 tw-bg-slate-100 tw-rounded-xl ">
              {paymentMethods && (
                <PaymentMethod
                  methods={paymentMethods}
                  onSelect={setSelectedMethod}
                />
              )}
              <fieldset className=" ">
                <div className="tw-space-y-5 tw-p-4">
                  <div className="tw-relative tw-flex tw-items-start">
                    <div className="tw-flex tw-h-6 tw-items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        checked={isCheckTandC}
                        onChange={handleTandC} // use onChange for better practice
                        className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300   
                               tw-text-indigo-600 tw-focus:tw-ring-indigo-600   
                               tw-transition tw-duration-200   
                               checked:tw:bg-indigo-600 checked:tw:bg-indigo-600   
                               focus:tw:bg-indigo-100"
                      />
                    </div>
                    <div className="tw-ml-3 tw-text-sm tw-leading-6 ">
                      <span
                        id="comments-description"
                        className="tw-text-gray-500"
                      >
                        আমি মজারু প্ল্যাটফর্ম ব্যবহারের শর্তাবলি ও গোপনীয়তা
                        নীতির ব্যাপারে সম্মতি দিচ্ছি
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`${
                      isCheckTandC ? "tw-bg-[#6F39B7]" : "tw-bg-[#cfb6f0]"
                    } tw-flex tw-w-full tw-justify-center tw-px-4 tw-py-3 tw-mt-6 tw-mb-24 tw-rounded-3xl`}
                    disabled={!isCheckTandC && !selectedMethod}
                    onClick={handlePaymentSubmit}
                  >
                    <div className=" tw-flex  tw-items-center">
                      <p className="tw-text-white tw-mr-2">পেমেন্ট করো</p>
                      <IoIosArrowForward className=" tw-text-white" size={18} />
                    </div>
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
