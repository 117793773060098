import React, { useEffect, useState } from "react";
import PageTitle from "../../shared/PageTitle/PageTitle";
import Book from "../../../../images/AllCourses/book.png";
import Icon from "../../../../images/AllCourses/loveIcon.png";
import Card from "../Home/AllCourses/Card/Card";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../shared/Loader/Loader";
import CardSkeleton from "../../shared/CardSkeleton/CardSkeleton";
import CourseNotFound from "../../shared/CourseNotFound/CourseNotFound";
import "./style.css";
import { HiFilter } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const AllCourses = ({ location }) => {
  const { pathname, state } = useLocation();
  const [courses, setCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [courseType, setCourseType] = useState(null);
  const [courseCategoryId, setCourseCategoryId] = useState(null);
  const [courseCategoryApiCall, setCourseCategoryApiCall] = useState(true);
  const [courseSubCategoryName, setCourseSubCategoryName] = useState("");
  const [courseCount, setCourseCount] = useState(0);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  const [isFixed, setIsFixed] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [seoData, setSeoData] = useState(null);
  //Spinner Button

  // SEO START
  useEffect(() => {
    let isMounted = true;
    async function fetchWebsiteSeoData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL_V2}seo-details?page=all_courses`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (isMounted) {
          setSeoData(data.data);
        }
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    }
    fetchWebsiteSeoData();
    return () => {
      isMounted = false;
    };
  }, []);

  // Translation Start
  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }
  //Translation End

  useEffect(() => {
    setCourseCategoryId(id);
  }, [id]);

  useEffect(() => {
    const type = pathname.substring(1, pathname.length);
    if (["live", "record", "free"].includes(type)) {
      setCourseType(type);
    } else {
      setCourseType("all");
    }
  }, [pathname]);

  useEffect(() => {
    async function fetchNotice() {
      setIsLoading(true);
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/course-sub-categories`
      );

      data = await data.json();
      setCategories(data?.data?.course_sub_categories);

      setIsLoading(false);
    }
    fetchNotice();
  }, [courseCategoryApiCall]);

  useEffect(() => {
    async function fetchCourses() {
      if (courseType) {
        setIsCourseLoading(true);
        let data = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/v2/courses?course_type=${courseType}`
        );
        data = await data.json();
        setCourses(data.data);
        setIsCourseLoading(false);
        setCourseCategoryId(null);
      }
    }
    fetchCourses();
  }, [courseType]);

  useEffect(() => {
    async function fetchCourses() {
      if (courseCategoryId) {
        setIsCourseLoading(true);
        let data = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/v2/courses?course_type=${courseType}&course_sub_category_id=${courseCategoryId}`
        );
        data = await data.json();
        setCourses(data.data);
        setIsCourseLoading(false);
      }
    }
    fetchCourses();
  }, [courseCategoryId, courseType]);

  // Handle changing the selected category
  const handleCategoryCheckboxChange = async (category) => {
    if (selectedCategoryId === category?.id) {
      // Unselect the category if it's already selected
      setSelectedCategoryId(null);
      setCourseCategoryId(null);
      setCourseSubCategoryName("");
      setCourseCount(0);
      navigate(`/${courseType}`);

      // Trigger API call to fetch courses without a category filter
      setIsCourseLoading(true); // Show loading spinner
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/courses?course_type=${courseType}`
      );
      data = await data.json();
      setCourses(data.data); // Set courses with the fetched data
      setIsCourseLoading(false); // Hide loading spinner
    } else {
      // Set the selected category
      setSelectedCategoryId(category?.id); // Set the selected category ID
      setCourseCategoryId(category?.id); // Set the actual course category ID to trigger data fetch
      setCourseSubCategoryName(category?.course_sub_category_name);
      setCourseCount(category?.courses_count);
      navigate(`/${courseType}?id=${category?.id}`); // Navigate with selected category filter

      // Trigger API call to fetch courses for the selected category
      setIsCourseLoading(true); // Show loading spinner
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/courses?course_type=${courseType}&course_sub_category_id=${category?.id}`
      );
      data = await data.json();
      setCourses(data.data); // Set courses with the fetched data
      setIsCourseLoading(false); // Hide loading spinner
    }
  };

  const handleCheckboxChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCourseType(e.target.value);
    navigate(`/${e.target.value}`); // Navigate to the selected type
  };

  const courseImagePath = courses?.course_image_path;

  // Handle mobile menu open
  const handleMobileMenuOpen = (e) => {
    setIsOpenMenu(true);
  };

  // Mobile filter button fixed at the top
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 290) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Mobile filter button fixed at the top

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData?.meta_title || "All Course"}</title>
        <meta
          property="og:title"
          content={
            seoData?.meta_title || "Mojaru - Learning Made Fun for Every Child"
          }
        />
        <meta
          name="description"
          content={
            seoData?.meta_descriptions ||
            "Mojaru Education Technologies Limited"
          }
        />
        <meta
          name="description"
          content={
            seoData?.meta_descriptions ||
            "Mojaru Education Technologies Limited"
          }
        />
      </Helmet>
      <div>
        {/* <PageTitle title="All Course"></PageTitle> */}
        <div className="tw-bg-gray-50 tw-pb-24">
          {/* Heading  */}
          <div className="">
            <div className="background tw-w-full tw-relative">
              <div className="tw-max-w-[1440px] tw-mx-auto tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center  tw-px-4">
                <div className="tw-mt-16 tw-text-center lg:tw-text-left">
                  <span className="tw-bg-[#ffce39] tw-px-4 tw-rounded-full tw-text-lg tw-font-semibold">
                    {t("All courses")}
                  </span>
                  <h1 className="tw-mt-4 tw-text-4xl sm:tw-text-6xl tw-text-white tw-font-semibold">
                    {t("All Courses of Mojaru")}
                  </h1>
                  <p className="tw-text-white tw-mt-4 tw-text-base sm:tw-text-2xl">
                    {t(
                      "Enhance your knowledge and develop a variety of skills with Mojaru"
                    )}
                  </p>
                </div>
                <div className="tw-hidden lg:tw-block lg:tw-mt-0">
                  <img className="" src={Book} alt="" />
                </div>
              </div>

              <div className="tw-block lg:tw-hidden lg:tw-mt-0 tw-absolute tw-top-10 tw-right-0">
                <img className=" tw-h-32 tw-w-32" src={Book} alt="" />
              </div>
            </div>
          </div>

          {/* Header bottom line bar  */}
          <div className=" tw-h-1 tw-bg-[#FFCE39] tw-w-full"></div>

          {/* Courses and category  */}
          <div className="tw-max-w-[1440px] md:tw-mx-auto md:tw-mt-4 tw-relative">
            <div className="md:tw-flex">
              {/* Sidebar Filter  */}
              <div className="">
                {/* ----------Mobile Menu NEW START----------  */}
                {/* Open and off button start*/}
                <div
                  className={`md:tw-hidden tw-mt-4 tw-mb-4 ${
                    isFixed
                      ? "tw-fixed tw-top-[3rem] tw-left-0 tw-w-full tw-z-40 tw-bg-white"
                      : ""
                  }`}
                >
                  <button className="tw-appearance-none tw-text-center tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6">
                    <span
                      className="tw-flex tw-justify-center tw-gap-3 tw-items-center"
                      onClick={(e) => handleMobileMenuOpen(e)}
                    >
                      কোর্স ফিল্টার করুন <HiFilter />
                    </span>
                  </button>
                </div>

                {/* Open and off button end*/}

                {/* Open and off button start*/}
                <div
                  className={`tw-w-full md:tw-hidden ${
                    isOpenMenu ? "tw-translate-y-0" : "tw-translate-y-full"
                  } tw-bg-white tw-h-[100%] tw-fixed tw-bottom-0 tw-z-50 tw-overflow-y-scroll tw-rounded-xl tw-transition-transform tw-duration-1000`}
                >
                  <div className="tw-mt-6 tw-p-4">
                    <h3 className="tw-text-lg tw-pb-3 tw-border-b">
                      {t("Course Categories")}
                    </h3>
                    <div>
                      {categories?.map((category) => (
                        <div
                          key={category?.id}
                          className="tw-h-auto tw-flex tw-items-center tw-mt-6"
                        >
                          <input
                            id={category?.course_category_id}
                            name="courseCategory"
                            type="checkbox"
                            value={category?.id}
                            checked={selectedCategoryId === category?.id} // Use selectedCategoryId for immediate checkbox update
                            onChange={() =>
                              handleCategoryCheckboxChange(category)
                            }
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <p className="tw-ml-3 tw-text-gray-700">
                            {category?.course_sub_category_name}
                            <span> ({category?.courses_count})</span>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="tw-flex tw-gap-4">
                      <button
                        className="tw-w-full tw-mt-4  tw-p-4 tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-[#532D80] tw-border-2 "
                        onClick={() => setIsOpenMenu(false)}
                      >
                        <span className="tw-font-bold tw-text-center tw-text-lg">
                          Cancel
                        </span>
                      </button>
                      <button
                        className="tw-w-full tw-mt-4  tw-p-4 tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-white"
                        onClick={() => setIsOpenMenu(false)}
                      >
                        <span className="tw-font-bold tw-text-center tw-text-lg">
                          Apply
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Open and off button end*/}

                {/* Filter options Start  */}
                <div
                  className={`tw-w-full md:tw-hidden ${
                    isOpenMenu ? "tw-translate-y-0" : "tw-translate-y-full"
                  } tw-bg-white tw-h-[100%] tw-fixed tw-bottom-0 tw-z-50 tw-overflow-y-scroll tw-rounded-xl tw-transition-transform tw-duration-1000`}
                >
                  {/* কোর্সের ধরণ */}
                  <div className="tw-mt-6 tw-p-4">
                    <div className="tw-flex tw-justify-end">
                      <RxCross2
                        size={25}
                        onClick={() => setIsOpenMenu(false)}
                      />
                    </div>

                    <h3 className="tw-text-lg tw-pb-3 tw-border-b">
                      {t("Course Types")}
                    </h3>
                    <div className="tw-mt-6">
                      {/* Radio buttons for course types */}
                      <div className="tw-h-auto tw-flex tw-items-center">
                        <input
                          id="all-courses"
                          name="courseType"
                          type="radio"
                          value="all"
                          checked={courseType === "all"}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                          }}
                          className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                        />
                        <span className="tw-ml-3 tw-text-gray-700">
                          {t("All Courses")}
                        </span>
                      </div>
                      <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                        <input
                          id="live-courses"
                          name="courseType"
                          type="radio"
                          value="live"
                          checked={courseType === "live"}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                          }}
                          className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                        />
                        <span className="tw-ml-3 tw-text-gray-700">
                          {t("Live Courses")}
                        </span>
                      </div>
                      <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                        <input
                          id="recorded-courses"
                          name="courseType"
                          type="radio"
                          value="record"
                          checked={courseType === "record"}
                          onChange={handleCheckboxChange} // Fix: onChange handles the change and sets courseType
                          className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                        />
                        <span className="tw-ml-3 tw-text-gray-700">
                          {t("Recorded Courses")}
                        </span>
                      </div>
                      <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                        <input
                          id="free-courses"
                          name="courseType"
                          type="radio"
                          value="free"
                          checked={courseType === "free"}
                          onChange={handleCheckboxChange} // Fix: onChange handles the change and sets courseType
                          className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                        />
                        <span className="tw-ml-3 tw-text-gray-700">
                          {t("Free Courses")}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* কোর্স ক্যাটাগরি */}
                  {/* Category section */}
                  <div className="tw-mt-6 tw-p-4">
                    <h3 className="tw-text-lg tw-pb-3 tw-border-b">
                      {t("Course Categories")}
                    </h3>
                    <div>
                      {categories?.map((category) => (
                        <div
                          key={category?.id}
                          className="tw-h-auto tw-flex tw-items-center tw-mt-6"
                        >
                          <input
                            id={category?.course_category_id}
                            name="courseCategory"
                            type="checkbox"
                            value={category?.id}
                            checked={selectedCategoryId === category?.id} // Use selectedCategoryId for immediate checkbox update
                            onChange={() =>
                              handleCategoryCheckboxChange(category)
                            }
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <p className="tw-ml-3 tw-text-gray-700">
                            {category?.course_sub_category_name}
                            <span> ({category?.courses_count})</span>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="tw-flex tw-gap-4">
                      <button
                        className="tw-w-full tw-mt-4  tw-p-4 tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-[#532D80] tw-border-2 "
                        onClick={() => setIsOpenMenu(false)}
                      >
                        <span className="tw-font-bold tw-text-center tw-text-lg">
                          Cancel
                        </span>
                      </button>
                      <button
                        className="tw-w-full tw-mt-4  tw-p-4 tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-white"
                        onClick={() => setIsOpenMenu(false)}
                      >
                        <span className="tw-font-bold tw-text-center tw-text-lg">
                          Apply
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* ----------Mobile Menu NEW END----------  */}

                {/* Tab Menu And Big Screen Start */}
                <div className="tw-hidden md:tw-block">
                  <div className="tw-w-full">
                    <div className="tw-mt-6 tw-p-4">
                      <h3 className="tw-text-lg tw-pb-3 tw-border-b">
                        {t("Course Types")}
                      </h3>
                      <div className="tw-mt-6">
                        <div className="tw-h-auto tw-flex tw-items-center">
                          <input
                            id="all-courses"
                            name="courseType"
                            type="radio"
                            value="all"
                            checked={courseType === "all"}
                            onChange={handleCheckboxChange}
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <span className="tw-ml-3 tw-text-gray-700">
                            {t("All Courses")}
                          </span>
                        </div>

                        <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                          <input
                            id="live-courses"
                            name="courseType"
                            type="radio"
                            value="live"
                            checked={courseType === "live"}
                            onChange={handleCheckboxChange}
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <span className="tw-ml-3 tw-text-gray-700">
                            {t("Live Courses")}
                          </span>
                        </div>

                        <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                          <input
                            id="recorded-courses"
                            name="courseType"
                            type="radio"
                            value="record"
                            checked={courseType === "record"}
                            onChange={handleCheckboxChange}
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <span className="tw-ml-3 tw-text-gray-700">
                            {t("Recorded Courses")}
                          </span>
                        </div>

                        <div className="tw-h-auto tw-flex tw-items-center tw-mt-6">
                          <input
                            id="free-courses"
                            name="courseType"
                            type="radio"
                            value="free"
                            checked={courseType === "free"}
                            onChange={handleCheckboxChange}
                            className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                          />
                          <span className="tw-ml-3 tw-text-gray-700">
                            {t("Free Courses")}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/*category*/}
                    <div className="tw-mt-6 tw-p-4">
                      <h3 className="tw-text-lg tw-pb-3 tw-border-b">
                        {t("Course Categories")}
                      </h3>
                      <div>
                        {categories?.map((category) => (
                          <div
                            key={category?.id}
                            className="tw-h-auto tw-flex tw-items-center tw-mt-6"
                          >
                            <input
                              id={category?.course_category_id}
                              name="courseCategory"
                              type="checkbox"
                              value={category?.id}
                              checked={selectedCategoryId === category?.id} // Use selectedCategoryId for immediate checkbox update
                              onChange={() =>
                                handleCategoryCheckboxChange(category)
                              }
                              className="tw-h-6 tw-w-6 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-600 custom-radio"
                            />
                            <p className="tw-ml-3 tw-text-gray-700">
                              {category?.course_sub_category_name}
                              <span> ({category?.courses_count})</span>
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right side course  */}
              <div className="md:tw-ml-10 tw-flex tw-w-full tw-justify-center">
                <div>
                  {courseSubCategoryName && (
                    <div className="tw-hidden md:tw-flex md:tw-items-center md:tw-mt-6">
                      <img src={Icon} alt="Icon" />
                      <p className="tw-ml-3 tw-text-2xl tw-font-semibold">
                        {courseSubCategoryName}
                      </p>
                    </div>
                  )}

                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-6 md:tw-mt-8">
                    {(isCourseLoading || courses === null) &&
                      // Show skeleton loaders while loading
                      [1, 2, 3, 4, 5, 6].map((item) => (
                        <CardSkeleton key={item} />
                      ))}
                    {!isCourseLoading &&
                      courses?.courses?.length > 0 &&
                      // Show courses if available
                      courses.courses.map((course) => (
                        <Card
                          key={course.id}
                          course={course}
                          courseImagePath={courseImagePath}
                        />
                      ))}
                  </div>
                  <div className="tw-w-full">
                    {!isCourseLoading &&
                      courses !== null &&
                      (!courses?.courses || courses.courses.length === 0) && (
                        <CourseNotFound />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
