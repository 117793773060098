// --------------------The below code is perfectly working except the regenerate button----------------------------
import React, { useEffect, useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import ListDropDown from "./ListDropDown";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import {
  fetchAvatarList,
  generateAvatarRequest,
  saveGeneratedImage,
} from "./utils/AvatarApi";
import Gif from "../../../../../images/Avatar/waitGif.gif";

function AvatarGenerate() {
  // State variables
  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [promptId, setPromptId] = useState(1);
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [generatedAvatar, setGeneratedAvatar] = useState(null);
  const [saveAvatar, setSaveAvatar] = useState();
  const [saveImageLoading, setSaveImageLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const notify = (msg) => toast(msg);

  // Handle image file input and generate a preview
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Remove the preview image
  const removePreviewImage = () => {
    setPreviewImage(null);
  };

  // Set the selected prompt ID
  const handleSetPrompt = (e, id) => {
    e.preventDefault();
    setPromptId(id);
  };

  // Generate an avatar using the uploaded image and prompt
  const generateAvatar = async () => {
    const user_id = localStorage.getItem("user_id");
    if (!user_id) {
      notify("Please log in first");
      return;
    }

    if (!imageFile) {
      notify("Please upload your image");
      return;
    }

    setIsButtonDisabled(true);

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("prompt_id", promptId);
    formData.append("image", imageFile);

    try {
      const response = await generateAvatarRequest(formData);
      if (response) {
        setGeneratedAvatar(response);
        notify("Avatar generation in progress...");
        console.log("--------Your Response is :----------");
        {
          response.status == 422 &&
            toast.error("Image is more than 1 MB", {
              position: "top-right",
            });
        }
        return;
      } else {
        notify("Please try again.");
      }
    } catch (err) {
      console.error("Error generating avatar:", err);
      console.log(err.errors);
      const errorMessage =
        err.status == 422 ? "Image is more than 1 MB" : "Something went wrong!";
      notify.error("Something went wrong!");
    }
  };

  // Fetch the list of avatars
  const fetchAvatarData = async () => {
    try {
      const data = await fetchAvatarList();
      setAvatars(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch avatar data on component mount
  useEffect(() => {
    fetchAvatarData();
  }, []);

  // Save the generated image with retry mechanism
  const saveImageWithRetry = (orderId) => {
    if (!orderId) return;
    setSaveImageLoading(true);
    const retryInterval = 15000; // Retry every 15 seconds
    const timeout = 60000; // Stop after 1 minute
    let retries = 0;

    const attemptSave = async () => {
      try {
        const saveImageResponse = await saveGeneratedImage(orderId);
        if (saveImageResponse?.success) {
          setSaveAvatar(saveImageResponse);
          notify("Image saved successfully!");
          fetchAvatarData();
          setSaveImageLoading(false);
          clearTimeout(timeoutId);
          clearInterval(intervalId);
          return true;
        }
      } catch (err) {
        console.error("Error saving image:", err);
      }
      return false;
    };

    const intervalId = setInterval(async () => {
      retries += 1;
      const success = await attemptSave();
      if (success) {
        clearInterval(intervalId);
      }
    }, retryInterval);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setSaveImageLoading(false);
      notify("Failed to save the image within the time limit.");
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  };

  // Trigger saveImageWithRetry after avatar generation
  useEffect(() => {
    if (generatedAvatar?.data?.order_id) {
      saveImageWithRetry(generatedAvatar.data.order_id);
    }
  }, [generatedAvatar]);

  // Render the UI based on avatar data and state
  const renderData = () => {
    const bucket_url = avatars?.data?.bucket_url;

    if (avatars?.data?.can_generate) {
      return (
        <div>
          <ListDropDown
            handleSetPrompt={handleSetPrompt}
            items={avatars?.data?.prompt}
          />
          <button
            onClick={generateAvatar}
            type="button"
            className="tw-text-white tw-bg-gradient-to-r tw-from-purple-500 tw-mt-2 tw-via-purple-600 tw-to-purple-700 tw-hover:bg-gradient-to-br tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5"
          >
            Generate
          </button>

          <p className=" tw-my-4">Your generated Avatar</p>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5">
            {avatars?.data?.avatar_list.map((img, index) =>
              img.avatar_image ? (
                <div
                  key={index}
                  className="tw-relative tw-w-fit tw-inline-block"
                >
                  <div className="tw-relative">
                    <img
                      src={`${bucket_url}${img.avatar_image}`}
                      alt={`Avatar ${index}`}
                      className="tw-h-52 tw-w-52 tw-rounded-md"
                    />
                    <HiOutlineDownload
                      size={25}
                      className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
                      onClick={() =>
                        downloadImage(
                          `${bucket_url}${img.avatar_image}`,
                          img.avatar_image.split("/").pop()
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className="tw-relative tw-w-fit tw-inline-block"
                >
                  <div className="tw-relative tw-h-52 tw-w-52 tw-rounded-md tw-bg-gray-200 animate-pulse"></div>
                  <button
                    className="tw-mt-2 tw-bg-blue-500 tw-text-white tw-rounded-lg tw-px-4 tw-py-2 hover:tw-bg-blue-600"
                    onClick={() => regenerateImage(img.order_id)}
                  >
                    Regenerate
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      );
    }

    if (!avatars?.data?.can_generate) {
      return (
        <div>
          <p>Sorry, your maximum limit has been reached!</p>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5">
            {avatars?.data?.avatar_list.map(
              (img, index) =>
                img.avatar_image && (
                  <div
                    key={index}
                    className="tw-relative tw-w-fit tw-inline-block"
                  >
                    <div className="tw-relative">
                      <img
                        src={`${bucket_url}${img.avatar_image}`}
                        alt={`Avatar ${index}`}
                        className="tw-h-52 tw-w-52 tw-rounded-md"
                      />
                      <HiOutlineDownload
                        size={25}
                        className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
                        onClick={() =>
                          downloadImage(
                            `${bucket_url}${img.avatar_image}`,
                            img.avatar_image.split("/").pop()
                          )
                        }
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      );
    }
  };

  // Download image helper function
  const downloadImage = (image_url, image_name) => {
    fetch(image_url)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.blob();
      })
      .then((blob) => saveAs(blob, image_name))
      .catch((error) => console.error("Download error:", error));
  };

  // if failed regenerate the image again
  const regenerateImage = async (orderId) => {
    if (!orderId) return;
    try {
      setSaveImageLoading(true);
      const response = await saveGeneratedImage(orderId);
      if (response?.success) {
        notify("Image regenerated successfully!");
        fetchAvatarData(); // Refresh avatar list
      } else {
        notify("Failed to regenerate image.");
      }
    } catch (err) {
      console.error("Error regenerating image:", err);
      notify("Error occurred while regenerating image.");
    } finally {
      setSaveImageLoading(false);
    }
  };

  return (
    <div>
      <div className="tw-bg-gray-100 tw-rounded-2xl tw-h-[70%] tw-w-[80%] tw-mx-auto tw-mt-32">
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-justify-center">
          {/* Left side: Image upload and preview */}
          <div>
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
              <label
                htmlFor="dropzone-file"
                className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-64 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50"
              >
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
                  <p className="tw-mb-2 tw-text-sm tw-text-gray-500">
                    <span className="tw-font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="tw-text-xs tw-text-gray-500">
                    SVG, PNG, JPG, or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="tw-hidden"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
            {previewImage && (
              <div className="tw-relative tw-mt-4 tw-flex tw-justify-center">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="tw-h-48 tw-w-auto tw-rounded-md tw-border tw-border-gray-300"
                />
                <button
                  onClick={removePreviewImage}
                  className="tw-absolute tw-top-1 tw-right-1 tw-bg-gray-600 tw-text-white tw-rounded-full tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-shadow-md hover:tw-bg-red-500"
                  aria-label="Remove image"
                >
                  ×
                </button>
              </div>
            )}
          </div>

          {/* Right side: Avatars and actions */}
          <div className="md:tw-ml-10">
            {renderData()}

            {saveImageLoading && (
              <div>
                <p>Please wait a moment...</p>
                <img src={Gif} className="tw-h-48 tw-w-48" alt="Loading..." />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvatarGenerate;

// --------------------The above code is perfectly working except the regenerate button----------------------------

// ------------------ With regenerate button start--------------------------------
// import React, { useEffect, useState } from "react";
// import { HiOutlineDownload } from "react-icons/hi";
// import ListDropDown from "./ListDropDown";
// import { saveAs } from "file-saver";
// import { toast } from "react-toastify";
// import {
//   fetchAvatarList,
//   generateAvatarRequest,
//   saveGeneratedImage,
// } from "./utils/AvatarApi";
// import Gif from "../../../../../images/Avatar/waitGif.gif";

// function AvatarGenerate() {
//   // State variables
//   const [avatars, setAvatars] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [saveImageLoading, setSaveImageLoading] = useState(false);

//   const notify = (msg) => toast(msg);

//   // Fetch the list of avatars
//   const fetchAvatarData = async () => {
//     try {
//       const data = await fetchAvatarList();
//       setAvatars(data);
//     } catch (err) {
//       console.error("Error fetching avatar list:", err);
//       notify("Failed to fetch avatar list.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch avatar data on component mount
//   useEffect(() => {
//     fetchAvatarData();
//   }, []);

//   // Regenerate image
//   const regenerateImage = async (orderId) => {
//     if (!orderId) return;
//     try {
//       setSaveImageLoading(true);
//       const response = await saveGeneratedImage(orderId);
//       if (response?.success) {
//         notify("Image regenerated successfully!");
//         fetchAvatarData(); // Refresh avatar list
//       } else {
//         notify("Failed to regenerate image.");
//       }
//     } catch (err) {
//       console.error("Error regenerating image:", err);
//       notify("Error occurred while regenerating image.");
//     } finally {
//       setSaveImageLoading(false);
//     }
//   };

//   // Download image helper function
//   const downloadImage = (image_url, image_name) => {
//     fetch(image_url)
//       .then((response) => {
//         if (!response.ok) throw new Error("Network response was not ok");
//         return response.blob();
//       })
//       .then((blob) => saveAs(blob, image_name))
//       .catch((error) => console.error("Download error:", error));
//   };

//   return (
//     <div>
//       {avatars?.data?.avatar_list.map((img, index) =>
//         img.avatar_image ? (
//           <div key={index} className="tw-relative tw-w-fit tw-inline-block">
//             <div className="tw-relative">
//               <img
//                 src={`${avatars.data.bucket_url}${img.avatar_image}`}
//                 alt={`Avatar ${index}`}
//                 className="tw-h-52 tw-w-52 tw-rounded-md"
//               />
//               <HiOutlineDownload
//                 size={25}
//                 className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
//                 onClick={() =>
//                   downloadImage(
//                     `${avatars.data.bucket_url}${img.avatar_image}`,
//                     img.avatar_image.split("/").pop()
//                   )
//                 }
//               />
//             </div>
//           </div>
//         ) : (
//           <div key={index} className="tw-relative tw-w-fit tw-inline-block">
//             <div className="tw-relative tw-h-52 tw-w-52 tw-rounded-md tw-bg-gray-200 animate-pulse"></div>
//             <button
//               className="tw-mt-2 tw-bg-blue-500 tw-text-white tw-rounded-lg tw-px-4 tw-py-2 hover:tw-bg-blue-600"
//               onClick={() => regenerateImage(img.order_id)}
//             >
//               Regenerate
//             </button>
//           </div>
//         )
//       )}
//       {saveImageLoading && <p>Loading...</p>}
//     </div>
//   );
// }

// export default AvatarGenerate;

// --------------------------------------------------

// import React, { useEffect, useState } from "react";
// import { HiOutlineDownload } from "react-icons/hi";
// import ListDropDown from "./ListDropDown";
// import { saveAs } from "file-saver";
// import { toast } from "react-toastify";
// import {
//   fetchAvatarList,
//   generateAvatarRequest,
//   saveGeneratedImage,
// } from "./utils/AvatarApi";
// import Gif from "../../../../../images/Avatar/waitGif.gif";
// import ClipLoader from "react-spinners/ClipLoader";

// function AvatarGenerate() {
//   // State variables
//   const [previewImage, setPreviewImage] = useState(null); // Preview of the uploaded image
//   const [imageFile, setImageFile] = useState(null); // File input for image
//   const [promptId, setPromptId] = useState(1); // Selected prompt ID
//   const [avatars, setAvatars] = useState([]); // List of avatars
//   const [loading, setLoading] = useState(true); // Loading state for fetching avatars
//   const [error, setError] = useState(null); // Error state for fetching avatars
//   const [generatedAvatar, setGeneratedAvatar] = useState(null); // Stores generated avatar response
//   const [saveAvatar, setSaveAvatar] = useState(); // Stores saved avatar data
//   const [saveImageLoading, setSaveImageLoading] = useState(false); // Loading state for saving image
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Disable state for generate button
//   const [isRegenerateButton, setIsRegenerateButton] = useState(false); // Regenerate button state
//   const [isGeneratingAvatar, setIsGeneratingAvatar] = useState(false); // Avatar generation progress state

//   const notify = (msg) => toast(msg); // Notification helper function

//   // Handle image file input and generate a preview
//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     setImageFile(file);
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setPreviewImage(reader.result); // Set image preview
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Remove the preview image
//   const removePreviewImage = () => {
//     setPreviewImage(null);
//   };

//   // Set the selected prompt ID
//   const handleSetPrompt = (e, id) => {
//     e.preventDefault();
//     setPromptId(id);
//   };

//   // Generate an avatar using the uploaded image and prompt
//   const generateAvatar = async () => {
//     const user_id = localStorage.getItem("user_id");
//     if (!user_id) {
//       notify("Please log in first");
//       return;
//     }

//     if (!imageFile) {
//       notify("Please upload your image");
//       return;
//     }

//     setIsButtonDisabled(true);

//     const formData = new FormData();
//     formData.append("user_id", user_id);
//     formData.append("prompt_id", promptId);
//     formData.append("image", imageFile);

//     try {
//       notify("Generating Your Image...");
//       const response = await generateAvatarRequest(formData);
//       if (response) {
//         setGeneratedAvatar(response);
//         notify("Avatar generation in progress...");
//         setIsGeneratingAvatar(true); // Show loading state
//       } else {
//         notify("Please try again.");
//       }
//     } catch (err) {
//       console.error("Error generating avatar:", err);
//       notify("Something went wrong!");
//     }
//   };

//   // Fetch the list of avatars
//   const fetchAvatarData = async () => {
//     try {
//       const data = await fetchAvatarList();
//       setAvatars(data);
//       console.log("--------Fetch Avatar List----------");
//       console.log(data);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch avatar data on component mount
//   useEffect(() => {
//     fetchAvatarData();
//     console.log("-----Fetched Data From API----");
//   }, []);

//   // Save the generated image after avatar is generated
//   const saveImage = async (orderId) => {
//     if (orderId) {
//       try {
//         setSaveImageLoading(true);
//         const saveImageResponse = await saveGeneratedImage(orderId);
//         if (saveImageResponse) {
//           setSaveAvatar(saveImageResponse);
//           notify("Image saved successfully!");
//           fetchAvatarData(); // Refresh avatar list
//         }
//       } catch (err) {
//         console.error("Error saving image:", err);
//         notify("Please try again!");
//       } finally {
//         setSaveImageLoading(false);
//       }
//     }
//   };

//   // Trigger saveImage after avatar generation with a delay
//   useEffect(() => {
//     let timeoutId;
//     if (generatedAvatar?.data?.order_id) {
//       timeoutId = setTimeout(
//         () => saveImage(generatedAvatar?.data?.order_id),
//         6000 // 6-second delay
//       );
//     }

//     return () => clearTimeout(timeoutId); // Cleanup timeout
//   }, [generatedAvatar]);

//   // Regenerate image
//   const regenerateImage = async (order_id) => {
//     setIsRegenerateButton(true);
//     saveImage(order_id);
//   };

//   // Download image helper function
//   const downloadImage = (image_url, image_name) => {
//     fetch(image_url)
//       .then((response) => {
//         if (!response.ok) throw new Error("Network response was not ok");
//         return response.blob();
//       })
//       .then((blob) => saveAs(blob, image_name))
//       .catch((error) => console.error("Download error:", error));
//   };

//   // Render the UI based on avatar data and state
//   const renderData = () => {
//     const bucket_url = avatars?.data?.bucket_url;

//     console.log("-----Your Balk url----");
//     console.log(bucket_url);

//     // Display prompt dropdown and generate button
//     if (avatars?.data?.can_generate) {
//       return (
//         <div>
//           <ListDropDown
//             handleSetPrompt={handleSetPrompt}
//             items={avatars?.data?.prompt}
//           />
//           <button
//             onClick={generateAvatar}
//             type="button"
//             className="tw-text-white tw-bg-gradient-to-r tw-from-purple-500 tw-mt-2 tw-via-purple-600 tw-to-purple-700 tw-hover:bg-gradient-to-br tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5"
//           >
//             Generate
//           </button>

//           <p className=" tw-my-4">Your generated Avatar</p>
//           <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5">
//             {avatars?.data?.avatar_list.map((img, index) => {
//               console.log("________Your Image---");

//               console.log(img.avatar_image); // Log the img object to console
//               return (
//                 img.avatar_image && (
//                   <div
//                     key={index}
//                     className="tw-relative tw-w-fit tw-inline-block"
//                   >
//                     <div className="tw-relative">
//                       <p>{img.avatar_image}</p>
//                       <p>{bucket_url}</p>
//                       {/* <p>{img.avatar_image}</p> */}
//                       <img
//                         src={`${bucket_url}${img.avatar_image}`}
//                         // src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/avatar_image/ai_image/677b8c011a3b0.jpg`}
//                         alt={`Avatar ${index}`}
//                         className="tw-h-52 tw-w-52 tw-rounded-md"
//                       />
//                       <HiOutlineDownload
//                         size={25}
//                         className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
//                         onClick={() =>
//                           downloadImage(
//                             `${bucket_url}${img.avatar_image}`,
//                             img.avatar_image.split("/").pop()
//                           )
//                         }
//                       />
//                     </div>
//                   </div>
//                 )
//               );
//             })}

//             {/* {avatars?.data?.avatar_list.map(
//               (img, index) =>
//                 img.avatar_image && (
//                   <div
//                     key={index}
//                     className="tw-relative tw-w-fit tw-inline-block"
//                   >
//                     <div className="tw-relative">
//                       <img
//                         src={`${bucket_url}${img.avatar_image}`}
//                         alt={`Avatar ${index}`}
//                         className="tw-h-52 tw-w-52 tw-rounded-md"
//                       />
//                       <HiOutlineDownload
//                         size={25}
//                         className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
//                         onClick={() =>
//                           downloadImage(
//                             `${bucket_url}${img.avatar_image}`,
//                             img.avatar_image.split("/").pop()
//                           )
//                         }
//                       />
//                     </div>
//                   </div>
//                 )
//             )} */}
//           </div>
//         </div>
//       );
//     }

//     if (!avatars?.data?.can_generate) {
//       return (
//         <div>
//           <p>Sorry Your maximum limit extend!!</p>
//           <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5">
//             {avatars?.data?.avatar_list.map(
//               (img, index) =>
//                 img.avatar_image && (
//                   <div
//                     key={index}
//                     className="tw-relative tw-w-fit tw-inline-block"
//                   >
//                     <div className="tw-relative">
//                       <img
//                         src={`${bucket_url}${img.avatar_image}`}
//                         alt={`Avatar ${index}`}
//                         className="tw-h-52 tw-w-52 tw-rounded-md"
//                       />
//                       <HiOutlineDownload
//                         size={25}
//                         className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
//                         onClick={() =>
//                           downloadImage(
//                             `${bucket_url}${img.avatar_image}`,
//                             img.avatar_image.split("/").pop()
//                           )
//                         }
//                       />
//                     </div>
//                   </div>
//                 )
//             )}
//           </div>
//         </div>
//       );
//     }
//   };

//   return (
//     <div>
//       <div className="tw-bg-gray-100 tw-rounded-2xl tw-h-[70%] tw-w-[80%] tw-mx-auto tw-mt-32">
//         <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-justify-center">
//           {/* Left side: Image upload and preview */}
//           <div>
//             <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
//               <label
//                 htmlFor="dropzone-file"
//                 className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-64 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50"
//               >
//                 <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
//                   <p className="tw-mb-2 tw-text-sm tw-text-gray-500">
//                     <span className="tw-font-semibold">Click to upload</span> or
//                     drag and drop
//                   </p>
//                   <p className="tw-text-xs tw-text-gray-500">
//                     SVG, PNG, JPG, or GIF (MAX. 800x400px)
//                   </p>
//                 </div>
//                 <input
//                   id="dropzone-file"
//                   type="file"
//                   className="tw-hidden"
//                   accept="image/*"
//                   onChange={handleImageUpload}
//                 />
//               </label>
//             </div>
//             {/* Image Preview  */}
//             {previewImage && (
//               <div className="tw-relative tw-mt-4 tw-flex tw-justify-center">
//                 <img
//                   src={previewImage}
//                   alt="Preview"
//                   className="tw-h-48 tw-w-auto tw-rounded-md tw-border tw-border-gray-300"
//                 />
//                 <button
//                   onClick={removePreviewImage}
//                   className="tw-absolute tw-top-1 tw-right-1 tw-bg-gray-600 tw-text-white tw-rounded-full tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-shadow-md hover:tw-bg-red-500"
//                   aria-label="Remove image"
//                 >
//                   ×
//                 </button>
//               </div>
//             )}
//           </div>

//           {/* Right side: Avatars and actions */}
//           <div className="md:tw-ml-10">
//             {renderData()}

//             {saveImageLoading && (
//               <div>
//                 <p>Please wait a moment...</p>
//                 <img src={Gif} className="tw-h-48 tw-w-48" alt="Loading..." />
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AvatarGenerate;

// // import React, { useEffect, useState } from "react";
// // import { HiOutlineDownload } from "react-icons/hi";
// // import ListDropDown from "./ListDropDown";
// // import { saveAs } from "file-saver";
// // import { toast } from "react-toastify";
// // import {
// //   fetchAvatarList,
// //   generateAvatarRequest,
// //   saveGeneratedImage,
// // } from "./utils/AvatarApi";
// // import Gif from "../../../../../images/Avatar/waitGif.gif";
// // import ClipLoader from "react-spinners/ClipLoader";
// // function AvatarGenerate() {
// //   const [previewImage, setPreviewImage] = useState(null); // Preview of the uploaded image
// //   const [imageFile, setImageFile] = useState(null); // File input
// //   const [promptId, setPromptId] = useState(1); // Selected prompt ID
// //   const [avatars, setAvatars] = useState([]); // List of avatars
// //   const [loading, setLoading] = useState(true); // Loading state for fetching avatars
// //   const [error, setError] = useState(null); // Error state for fetching avatars
// //   const [generatedAvatar, setGeneratedAvatar] = useState(null); // Stores generated avatar response
// //   const [saveAvatar, setSaveAvatar] = useState(); // Stores saved avatar data
// //   const [saveImageLoading, setSaveImageLoading] = useState(false); // Loading state for saving image
// //   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
// //   const [isRegenerateButton, setIsRegenerateButton] = useState(false);
// //   const [isGeneratingAvatar, setIsGeneratingAvatar] = useState(false);

// //   const notify = (msg) => toast(msg); // Notification function

// //   // Handle file input change and generate preview
// //   const handleImageUpload = (event) => {
// //     const file = event.target.files[0];
// //     setImageFile(file);
// //     if (file) {
// //       const reader = new FileReader();
// //       reader.onload = () => {
// //         setPreviewImage(reader.result); // Set the image preview
// //       };
// //       reader.readAsDataURL(file);
// //     }
// //   };

// //   // Remove the preview image
// //   const removePreviewImage = () => {
// //     setPreviewImage(null);
// //   };

// //   // Set the selected prompt ID
// //   const handleSetPrompt = (e, id) => {
// //     e.preventDefault();
// //     setPromptId(id);
// //   };

// //   // Function to generate an avatar
// //   const generateAvatar = async () => {
// //     const user_id = localStorage.getItem("user_id");
// //     if (!user_id) {
// //       notify("Please log in first");
// //       return;
// //     }

// //     const formData = new FormData();
// //     formData.append("user_id", user_id);
// //     formData.append("prompt_id", promptId);
// //     formData.append("image", imageFile);

// //     if (!imageFile) {
// //       notify("Please upload your image");
// //       return;
// //     }

// //     setIsButtonDisabled(true);

// //     try {
// //       notify("Generating Your Image...");
// //       const response = await generateAvatarRequest(formData); // Make the API request
// //       if (response) {
// //         setGeneratedAvatar(response); // Store the generated avatar response
// //         notify("Avatar generating on process");
// //         setIsGeneratingAvatar(true); //generate gif enable
// //       } else {
// //         notify("Please try again.");
// //       }
// //     } catch (err) {
// //       console.error("Error generating avatar:", err);
// //       notify("Something went wrong!");
// //     }
// //   };

// //   // Fetch the list of avatars
// //   const fetchAvatarData = async () => {
// //     try {
// //       const data = await fetchAvatarList(); // Make API request to fetch avatar list
// //       setAvatars(data);
// //     } catch (err) {
// //       setError(err.message); // Set error state
// //     } finally {
// //       setLoading(false); // Set loading state to false
// //     }
// //   };

// //   // Run fetchAvatarData on component mount
// //   useEffect(() => {
// //     fetchAvatarData();
// //   }, []);

// //   // Save the generated image
// //   const saveImage = async (orderId) => {
// //     if (orderId) {
// //       try {
// //         setSaveImageLoading(true);
// //         const saveImageResponse = await saveGeneratedImage(orderId); // Make API request to save the image
// //         if (saveImageResponse) {
// //           setSaveImageLoading(false);
// //           setSaveAvatar(saveImageResponse); // Store saved avatar data
// //           notify("Image saved successfully!");
// //           fetchAvatarData();
// //         }
// //       } catch (err) {
// //         console.error("Error saving image:", err);
// //         notify("Please try again!");
// //       }
// //     }
// //   };

// //   // Trigger saveImage after generating the avatar
// //   useEffect(() => {
// //     let timeoutId;
// //     if (generatedAvatar?.data?.order_id) {
// //       timeoutId = setTimeout(
// //         () => saveImage(generatedAvatar?.data?.order_id),
// //         6000 // Add a 5-second delay before saving
// //       );
// //     }

// //     return () => {
// //       clearTimeout(timeoutId); // Clear timeout on cleanup
// //     };
// //   }, [generatedAvatar]);

// //   //Render Show Logic
// //   const regenerateImage = async (order_id) => {
// //     setIsRegenerateButton(true);
// //     saveImage(order_id);
// //   };

// //   // File download handler
// //   // const handleDownloadImage = (imageUrl, imageName) => {
// //   //   // Validate the URL
// //   //   if (!imageUrl) {
// //   //     console.error("Invalid image URL");
// //   //     return;
// //   //   }

// //   //   // Create an anchor element
// //   //   const a = document.createElement("a");
// //   //   a.href = imageUrl; // Set the image URL
// //   //   a.download = imageName; // Set the downloaded file name
// //   //   a.target = "_blank"; // Open in a new tab if needed

// //   //   // Append to the DOM, trigger download, and remove
// //   //   document.body.appendChild(a);
// //   //   a.click();
// //   //   document.body.removeChild(a);
// //   // };

// //   // const downloadImage = (image_url, image_name) => {
// //   //   console.log(image_url, image_name);
// //   //   saveAs(image_url, image_name); // Put your image URL here.
// //   // };

// //   const downloadImage = (image_url, image_name) => {
// //     fetch(image_url)
// //       .then((response) => {
// //         if (!response.ok) {
// //           throw new Error("Network response was not ok");
// //         }
// //         return response.blob();
// //       })
// //       .then((blob) => {
// //         saveAs(blob, image_name);
// //       })
// //       .catch((error) => {
// //         console.error("There was a problem with the download:", error);
// //       });
// //   };

// //   // Main render

// //   const renderData = () => {
// //     const bucket_url = avatars?.data?.bucket_url;
// //     if (
// //       avatars?.data?.avatar_list.length === 0 &&
// //       avatars?.data?.can_generate
// //     ) {
// //       return (
// //         <div>
// //           <ListDropDown handleSetPrompt={handleSetPrompt} />
// //           <button
// //             onClick={generateAvatar}
// //             type="button"
// //             className="tw-text-white tw-bg-gradient-to-r tw-from-purple-500 tw-mt-2 tw-via-purple-600 tw-to-purple-700 tw-hover:bg-gradient-to-br tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center"
// //           >
// //             Generate
// //           </button>
// //         </div>
// //       );
// //     } else if (
// //       avatars?.data?.avatar_list.length < 2 &&
// //       avatars?.data?.can_generate
// //     ) {
// //       return (
// //         <div>
// //           <div className="tw-mt-2 tw-mr-2">
// //             <ListDropDown
// //               handleSetPrompt={handleSetPrompt}
// //               className="tw-my-2"
// //             />
// //             <button
// //               onClick={generateAvatar}
// //               type="button"
// //               className="tw-text-white tw-bg-gradient-to-r tw-from-purple-500 tw-via-purple-600 tw-mt-2 tw-to-purple-700 tw-hover:bg-gradient-to-br tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center"
// //               disabled={isButtonDisabled}
// //             >
// //               Generate
// //             </button>
// //           </div>

// //           {/* Display Avatar Images */}
// //           <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5">
// //             {/* {avatars?.data?.avatar_list.map((img, index) => (
// //               <div key={index} className="tw-relative tw-w-fit tw-inline-block">
// //                 <div className="tw-relative">
// //                   <img
// //                     src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                     alt={`Avatar ${index}`}
// //                     className="tw-h-52 tw-w-52 tw-rounded-md"
// //                   />
// //                   <HiOutlineDownload
// //                     size={25}
// //                     className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
// //                     onClick={() =>
// //                       downloadImage(
// //                         `${bucket_url}${img.avatar_image}`,
// //                         img.avatar_image.split("/").pop()
// //                       )
// //                     }
// //                   />
// //                 </div>
// //               </div>
// //             ))} */}
// //             {avatars?.data?.avatar_list.map((img, index) => {
// //               if (img.avatar_image !== null) {
// //                 return (
// //                   <div
// //                     key={index}
// //                     className="tw-relative tw-w-fit tw-inline-block"
// //                   >
// //                     <div className="tw-relative">
// //                       <img
// //                         src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                         alt={`Avatar ${index}`}
// //                         className="tw-h-52 tw-w-52 tw-rounded-md"
// //                       />
// //                       <HiOutlineDownload
// //                         size={25}
// //                         className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
// //                         onClick={() =>
// //                           downloadImage(
// //                             `${bucket_url}${img.avatar_image}`,
// //                             img.avatar_image.split("/").pop()
// //                           )
// //                         }
// //                       />
// //                     </div>
// //                   </div>
// //                 );
// //               } else {
// //                 return (
// //                   <div>
// //                     <div className="tw-grid tw-grid-cols-2">
// //                       <img
// //                         key={index} // Add a unique key for each item
// //                         src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                         alt={`Avatar ${index}`}
// //                         className="tw-h-52 tw-w-52 "
// //                       />
// //                     </div>
// //                     <div>
// //                       <button
// //                         onClick={() => saveImage(img.order_id)} // This is incorrect
// //                         className="tw-bg-[#532D80] tw-rounded-md tw-text-white tw-px-4 tw-py-2"
// //                       >
// //                         Regenerate
// //                       </button>
// //                     </div>
// //                   </div>
// //                 );
// //               }
// //             })}
// //           </div>
// //         </div>
// //       );
// //     } else if (
// //       avatars?.data?.avatar_list.length === 2 &&
// //       !avatars?.data?.can_generate
// //     ) {
// //       return (
// //         <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-5 md:tw-mt-0">
// //           {/* {avatars?.data?.avatar_list.map((img, index) => (
// //             <div
// //               key={index}
// //               className="tw-relative tw-w-fit tw-inline-block tw-my-5"
// //             >
// //               <div className="tw-relative">
// //                 <img
// //                   src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                   alt={`Avatar ${index}`}
// //                   className="tw-h-52 tw-w-52 tw-rounded-md"
// //                 />
// //                 <HiOutlineDownload
// //                   size={25}
// //                   className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
// //                   onClick={() =>
// //                     downloadImage(
// //                       `${bucket_url}${img.avatar_image}`,
// //                       img.avatar_image.split("/").pop()
// //                     )
// //                   }
// //                 />
// //               </div>
// //             </div>
// //           ))} */}
// //           {avatars?.data?.avatar_list.map((img, index) => {
// //             if (img.avatar_image !== null) {
// //               return (
// //                 <div
// //                   key={index}
// //                   className="tw-relative tw-w-fit tw-inline-block"
// //                 >
// //                   <div className="tw-relative">
// //                     <img
// //                       src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                       alt={`Avatar ${index}`}
// //                       className="tw-h-52 tw-w-52 tw-rounded-md"
// //                     />
// //                     <HiOutlineDownload
// //                       size={25}
// //                       className="tw-absolute tw-top-2 tw-right-2 tw-text-white hover:tw-cursor-pointer"
// //                       onClick={() =>
// //                         downloadImage(
// //                           `${bucket_url}${img.avatar_image}`,
// //                           img.avatar_image.split("/").pop()
// //                         )
// //                       }
// //                     />
// //                   </div>
// //                 </div>
// //               );
// //             } else {
// //               return (
// //                 <div>
// //                   <div className="tw-grid tw-grid-cols-2">
// //                     <img
// //                       key={index} // Add a unique key for each item
// //                       src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${img.avatar_image}`}
// //                       alt={`Avatar ${index}`}
// //                       className="tw-h-52 tw-w-52 "
// //                     />
// //                   </div>
// //                   <div>
// //                     <button
// //                       onClick={() => saveImage(img.order_id)} // This is incorrect
// //                       className="tw-bg-[#532D80] tw-rounded-md tw-text-white tw-px-4 tw-py-2"
// //                     >
// //                       Regenerate
// //                     </button>
// //                   </div>
// //                 </div>
// //               );
// //             }
// //           })}
// //         </div>
// //       );
// //     }
// //   };

// //   return (
// //     <div>
// //       <div className="tw-bg-gray-100 tw-rounded-2xl tw-h-[70%] tw-w-[80%] tw-mx-auto tw-mt-32">
// //         <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-justify-center">
// //           {/* Left Side: Image upload and preview */}
// //           <div>
// //             <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
// //               <label
// //                 htmlFor="dropzone-file"
// //                 className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-64 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50"
// //               >
// //                 <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
// //                   <p className="tw-mb-2 tw-text-sm tw-text-gray-500">
// //                     <span className="tw-font-semibold">Click to upload</span> or
// //                     drag and drop
// //                   </p>
// //                   <p className="tw-text-xs tw-text-gray-500">
// //                     SVG, PNG, JPG, or GIF (MAX. 800x400px)
// //                   </p>
// //                 </div>
// //                 <input
// //                   id="dropzone-file"
// //                   type="file"
// //                   className="tw-hidden"
// //                   accept="image/*"
// //                   onChange={handleImageUpload}
// //                 />
// //               </label>
// //             </div>

// //             {previewImage && (
// //               <div className="tw-relative tw-mt-4 tw-flex tw-justify-center">
// //                 <img
// //                   src={previewImage}
// //                   alt="Preview"
// //                   className="tw-h-48 tw-w-auto tw-rounded-md tw-border tw-border-gray-300"
// //                 />
// //                 <button
// //                   onClick={removePreviewImage}
// //                   className="tw-absolute tw-top-1 tw-right-1 tw-bg-gray-600 tw-text-white tw-rounded-full tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-shadow-md hover:tw-bg-red-500"
// //                   aria-label="Remove image"
// //                 >
// //                   ×
// //                 </button>
// //               </div>
// //             )}

// //             <button className="">Generate Avatar</button>
// //           </div>

// //           {/* Right Side: Avatars and actions */}
// //           <div className="md:tw-ml-10">
// //             {renderData()}{" "}
// //             {saveImageLoading && (
// //               <div>
// //                 <p>Please wait a moment...</p>
// //                 <img src={Gif} className="tw-h-48 tw-w-48" />
// //               </div>
// //             )}
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default AvatarGenerate;
