import Header from "../Header/Header";

import Steps from "./Components/Steps";
import Breadcrumbs from "./Components/Breadcrumbs";
import CourseDetails from "./Components/CourseDetails";

const AddToCart = () => {
  return (
    <div className="">
      <Header />
      <div className="tw-max-w-[1300px] tw-mx-auto tw-mt-10">
        <Breadcrumbs />
        <Steps />
        <CourseDetails />
      </div>
    </div>
  );
};

export default AddToCart;
