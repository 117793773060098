import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import StudentFeedback from "./StudentFeedback";
const FeedbackCheck = ({ children }) => {
  const userId = localStorage.getItem("user_id");
  const auth_token = localStorage.getItem("auth_token");

  const [isLoading, setIsLoading] = useState(true);
  const [hasPendingFeedback, setHasPendingFeedback] = useState(null);
  const [pendingFeedback, setPendingFeedback] = useState([]);

  useEffect(() => {
    feedbackDataListFetch();
  }, []);

  const feedbackDataListFetch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/student/pending-feedback-list/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth_token}`,
            SecretKey: process.env.REACT_APP_API_URL_SECRET_KEY,
          },
        }
      );

      const data = await response.json();
      setPendingFeedback(data);
      if (data?.data?.pending_feedbacks?.length === 0) {
        setHasPendingFeedback(false);
      } else {
        setHasPendingFeedback(true);
      }
    } catch (error) {
      //   setHasPendingFeedback(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <Skeleton className="mb-1" height={50} width={100} />
        <Skeleton className="mb-1" height={20} width={200} />
        <div className="row g-3">
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton
              style={{ width: "100%", height: "450px", borderRadius: "1rem" }}
            />
          </div>
        </div>
      </>
    );
  }

  if (hasPendingFeedback) {
    return (
      <StudentFeedback
        pendingFeedbackData={pendingFeedback?.data?.pending_feedbacks?.[0]}
        showModal={true}
        feedbackQuestion={pendingFeedback?.data?.feedback_questions}
        feedbackDataDetails={pendingFeedback?.data}
      />
    );
  }

  return children;
};

export default FeedbackCheck;
