import React, { useState } from "react";

function PaymentMethod({ methods, onSelect }) {
  const [selectedMethod, setSelectedMethod] = useState(null);

  const handleSelect = (method) => {
    setSelectedMethod(method.id);
    onSelect(method.id);
  };
  return (
    <div className="tw-p-4 ">
      {methods.map((method) => (
        <label
          key={method.id}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <input
            type="radio"
            name="paymentMethod"
            value={method.id}
            checked={selectedMethod === method.id}
            onChange={() => handleSelect(method)}
            style={{ marginRight: "10px" }}
          />
          <img
            src={method.icon}
            alt={method.title}
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
          />
          {method.title}
        </label>
      ))}
    </div>
  );
}

export default PaymentMethod;
