import React, { useEffect, useState } from "react";
import { FiPhoneForwarded } from "react-icons/fi";
import { SlCallOut } from "react-icons/sl";
import Logo from "../../../../../images/Reve/Logo.svg";
import ChatIcon from "../../../../../images/Reve/chatIcon.svg";
import User1 from "../../../../../images/Reve/suppot1.jpg";
import User2 from "../../../../../images/Reve/support2.jpg";
import User3 from "../../../../../images/Reve/suppot3.jpg";
import Arrow from "../../../../../images/Reve/Arrow.svg";
import WhatsApp from "../../../../../images/Reve/whatsapp.svg";
import Abacus from "../../../../../images/Reve/Abacus.svg";
import English from "../../../../../images/Reve/English.svg";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import "./Reve.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

function ReveChat({ handleButton, handleSpinner, isOpenCTA }) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  const [isChatOpen, setIsChatOpen] = useState(false);

  //Chat Open Handler
  const isChatOpenHandler = () => {
    setIsChatOpen(true);
  };
  const whatsAppPhoneNumber = "+8801610075103"; // Your WhatsApp number
  const whatsappLink = `https://wa.me/${whatsAppPhoneNumber}`; // Create the link

  //WHatsAPp call
  const handleButtonClickWhatsApp = () => {
    window.open(whatsappLink, "_blank"); // Open WhatsApp chat in a new tab
  };
  const phoneNumber = "09610990880";

  // Phone Call
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`; // Initiate call using tel protocol
  };
  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }

  //Translation End
  const [isScriptAdded, setIsScriptAdded] = useState(false);

  const addReveChatScript = () => {
    if (isScriptAdded) {
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.setAttribute("charset", "utf-8");
    script.src =
      (document.location.protocol === "https:" ? "https://" : "http://") +
      "static.revechat.com/widget/scripts/new-livechat.js?" +
      new Date().getTime();

    script.onload = () => {
    
      window.$_REVECHAT_API =
        window.$_REVECHAT_API ||
        function (c) {
          window.$_REVECHAT_API._.push(c);
        };
      window.$_REVECHAT_API._ = [];
      window.__revechat_account = "10923613"; // Replace with your ReveChat account ID
      window.__revechat_version = 2;
    };

    document.body.appendChild(script);
    setIsScriptAdded(true);
  };

  const chatOpenHandler = (e) => {
    e.preventDefault();
    handleSpinner(e);
    addReveChatScript();
  };

  return (
    <>
      <div className="tw-fixed tw-bottom-[4.5rem] tw-z-50  tw-right-[2%] md:tw-right-[2%]">
        <div className="tw-rounded-full">
          <div className="tw-z-50">
            {/* Close button  */}
            <span
              className="tw-bg-white tw-rounded-full tw-inline-block tw-p-[3px] hover:tw-cursor-pointer"
              onClick={() => {
                handleButton(false);
              }}
            >
              <RxCross1 size={20} className=" tw-text-gray-400" />
            </span>
            {/* Container  */}
            <div className="tw-max-w-[375px] tw-bg-[#F5F3FF] tw-h-auto tw-overflow-y-scroll custom-scroll tw-rounded-xl">
              <div className="tw-pt-8 tw-px-4">
                {/* Logo */}
                <img src={Logo} alt="Mojaru Logo" />
                <h1 className="tw-text-[#000000] tw-text-[22px] tw-py-4 tw-font-semibold tw-font-epilogue-font tw-mt-4">
                  {t("Lets start live chat with our team!")}
                </h1>

                {/* Start a conversion */}
                {/* <span className=" tw-flex tw-items-center tw-gap-4">
                  <img src={ChatIcon} alt="Chat" /> <p>Start a conversion</p>
                </span> */}

                {/* Users  */}
                <div className="tw-mt-2 tw-flex tw-gap-3">
                  <div className="tw-flex">
                    <img
                      src={User1}
                      alt=""
                      className="tw-h-11 tw-w-11 tw-rounded-full"
                    />

                    <img
                      src={User2}
                      alt=""
                      className="tw-h-11 tw-w-11 -tw-ml-4 tw-rounded-full"
                    />

                    <img
                      src={User3}
                      alt=""
                      className="tw-h-11 tw-w-11  -tw-ml-4 tw-rounded-full"
                    />
                  </div>
                  <div>
                    <p className="tw-font-extralight">
                      {t("Our usual reply time")}
                    </p>
                    <p className="tw-font-semibold">
                      {t("Typically replies a few minutes")}
                    </p>
                  </div>
                </div>

                {/* Live chat button */}
                <div className="tw-flex tw-mt-4">
                  <button
                    className="tw-bg-[#6F39B7] tw-text-white tw-w-full tw-rounded-3xl"
                    onClick={chatOpenHandler}
                  >
                    <span className="tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
                      <img src={Arrow} alt="" />
                      {t("Live chat with us")}
                    </span>
                  </button>
                </div>
                {/* Whats App Button  */}
                <div className=" tw-flex tw-mt-4">
                  <button
                    className=" tw-bg-white tw-text-green-500 tw-w-full tw-rounded-3xl"
                    onClick={handleButtonClickWhatsApp}
                  >
                    <span className=" tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
                      <img src={WhatsApp} alt="" />
                      {t("WhatsUp msg")}
                    </span>
                  </button>
                </div>

                {/* Course Card Desgin */}
                <div className="tw-flex tw-gap-2 tw-items-center tw-justify-center">
                  {/* Course 1  */}
                  <div className="tw-w-auto tw-h-auto tw-p-2 tw-bg-white tw-rounded-lg tw-mt-4 hover:tw-cursor-pointer ">
                    <img src={Abacus} alt="" />
                    <div className="tw-py-1">
                      <p className=" tw-font-semibold">Abacus mind math</p>
                      <p className=" tw-font-light tw-text-gray-500 tw-pb-1">
                        {t("4 to 13 years")}
                      </p>
                    </div>
                    <Link
                      to={
                        "https://mojaru.com/genius/abacus-mind-math-foundation"
                      }
                      className=" tw-text-blue-400"
                    >
                      {t("Book a free session")}
                    </Link>
                  </div>

                  {/* Course 2  */}
                  <div className="tw-w-auto tw-h-auto tw-p-2 tw-bg-white tw-rounded-lg tw-mt-4 hover:tw-cursor-pointer">
                    <img src={English} alt="" />
                    <div className="tw-py-1">
                      <p className=" tw-font-semibold">{t("Smart english")}</p>
                      <p className=" tw-font-light tw-text-gray-500 tw-pb-1">
                        {t("5 to 11 years")}
                      </p>
                    </div>
                    <Link
                      to={"https://mojaru.com/genius/smart-english-for-kids"}
                      className=" tw-text-blue-400"
                    >
                      {t("Book a free session")}
                    </Link>
                  </div>
                </div>
                {/* One to one button */}
                <div className=" tw-flex tw-mt-4 tw-mb-2">
                  <Link
                    to={"https://mojaru.com/one-to-one"}
                    className=" tw-bg-white tw-text-[#374151] tw-w-full tw-rounded-3xl tw-px-2"
                  >
                    <span className=" tw-flex tw-justify-center tw-items-center tw-font-semibold">
                      {t("One-to-One or Batch with Multiple Courses")}
                    </span>
                  </Link>
                </div>

                {/* line bar  */}
                <div className=" tw-bg-gray-400 tw-h-[0.15rem] tw-w-full" />
                {/* Other components (WhatsApp, course cards, etc.) */}
                {/* Call Button */}
                <div className="tw-flex tw-py-4">
                  <button
                    className="tw-bg-[#22C55E] tw-text-white tw-w-full tw-rounded-3xl"
                    // href={`tel:${phoneNumber}`}
                    onClick={handleCallClick}
                  >
                    <span className="tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
                      <SlCallOut />
                      {t("Call us")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReveChat;

// function ReveChat() {
//   const [isClicked, setIsClicked] = useState(false);

// //when this button will click then the script will be added to the react root html page
//   const clickHandler = (e) => {
//     e.preventDefault();
//   };

//   const scriptURL = "https://example.com/third-party-script.js";

//   const chatOpenHandler = (e) => {
//     e.preventDefault();
//   };

//   return (
//     <>
//       {/* CTA button  */}
// {/* <div className="tw-bg-[#bfa8ff] tw-fixed tw-top-[0%] tw-rounded-full tw-right-[14%] tw-m-0 tw-z-50 tw-flex tw-justify-center tw-items-center hover:tw-cursor-pointer tw-h-auto"> */}
// <div className=" tw-fixed tw-bottom-[4.5rem] tw-z-50 tw-right-[2%]">
//   <div className="tw-rounded-full">
//     {/* Chat boot design  */}
//     <div className="tw-z-50">
//       <div className="tw-w-[375px] tw-bg-[#F5F3FF] tw-h-auto tw-rounded-xl">
//         <div className="tw-pt-8 tw-px-4">
//           {/* Logo  */}
//           <img src={Logo} alt="Mojaru Logo" />
//           <h1 className=" tw-text-[#000000] tw-text-[28px] tw-py-6 tw-font-semibold tw-font-epilogue-font tw-mt-4">
//             Lets start live chat with our team!
//           </h1>
//           {/* Start a conversion */}
//           <span className=" tw-flex tw-items-center tw-gap-4">
//             <img src={ChatIcon} alt="Chat" /> <p>Start a conversion</p>
//           </span>
//           {/* Users  */}
//           <div className="tw-mt-2 tw-flex tw-gap-3">
//             <div className="tw-flex">
//               <img src={User1} alt="" className="tw-h-10 tw-w-10" />

//               <img
//                 src={User2}
//                 alt=""
//                 className="tw-h-10 tw-w-10 -tw-ml-4"
//               />

//               <img
//                 src={User3}
//                 alt=""
//                 className="tw-h-10 tw-w-10  -tw-ml-4"
//               />
//             </div>
//             <div>
//               <p className="tw-font-extralight">Our usual reply time</p>
//               <p className="tw-font-semibold">
//                 Typically replies a few minutes{" "}
//               </p>
//             </div>
//           </div>
//           {/* Live chat button  */}
//           <div className=" tw-flex tw-mt-4">
//             <button
//               className=" tw-bg-[#6F39B7] tw-text-white tw-w-full tw-rounded-3xl"
//               onClick={(e) => {
//                 chatOpenHandler(e);
//               }}
//             >
//               <span className=" tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
//                 <img src={Arrow} alt="" />
//                 Live chat with us
//               </span>
//             </button>
//           </div>
//           {/* Whats App Button  */}
//           <div className=" tw-flex tw-mt-4">
//             <button className=" tw-bg-white tw-text-green-500 tw-w-full tw-rounded-3xl">
//               <span className=" tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
//                 <img src={WhatsApp} alt="" />
//                 Live chat with us
//               </span>
//             </button>
//           </div>

//           {/* Course Card Desgin */}
//           <div className="tw-flex tw-gap-2 tw-items-center tw-justify-center">
//             {/* Course 1  */}
//             <div className="tw-w-auto tw-h-auto tw-p-2 tw-bg-white tw-rounded-lg tw-mt-4 hover:tw-cursor-pointer ">
//               <img src={Abacus} alt="" />
//               <div className="tw-py-1">
//                 <p className=" tw-font-semibold">Abacus mind math</p>
//                 <p className=" tw-font-light tw-text-gray-500 tw-pb-1">
//                   4 to 13 years
//                 </p>
//               </div>
//               <Link
//                 to={
//                   "https://mojaru.com/genius/abacus-mind-math-foundation"
//                 }
//                 className=" tw-text-blue-400"
//               >
//                 Book a free session
//               </Link>
//             </div>

//             {/* Course 2  */}
//             <div className="tw-w-auto tw-h-auto tw-p-2 tw-bg-white tw-rounded-lg tw-mt-4 hover:tw-cursor-pointer">
//               <img src={English} alt="" />
//               <div className="tw-py-1">
//                 <p className=" tw-font-semibold">Smart english</p>
//                 <p className=" tw-font-light tw-text-gray-500 tw-pb-1">
//                   5 to 11 years
//                 </p>
//               </div>
//               <Link
//                 to={
//                   "https://mojaru.com/genius/abacus-mind-math-foundation"
//                 }
//                 className=" tw-text-blue-400"
//               >
//                 Book a free session
//               </Link>
//             </div>
//           </div>

//           {/* One to one button */}
//           <div className=" tw-flex tw-mt-4 tw-mb-2">
//             <Link
//               to={"https://mojaru.com/one-to-one"}
//               className=" tw-bg-white tw-text-[#374151] tw-w-full tw-rounded-3xl tw-px-2"
//             >
//               (One-to-One) or Batch with Multiple Courses
//             </Link>
//           </div>

//           {/* line bar  */}
//           <div className=" tw-bg-gray-400 tw-h-[0.15rem] tw-w-full" />

//           {/* Call Button  */}
//           <div className=" tw-flex tw-py-4 ">
//             <button className=" tw-bg-[#22C55E] tw-text-white tw-w-full tw-rounded-3xl">
//               <span className=" tw-flex tw-justify-center tw-items-center tw-py-2 tw-gap-3">
//                 <IoCallOutline />
//                 Call us 09610990880
//               </span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//     {/* <div className=" tw-absolute" onClick={clickHandler}>
//     <FiPhoneForwarded className=" tw-text-white" />
//   </div> */}
//   </div>
// </div>
//     </>
//   );
// }

// export default ReveChat;
