import React, { useState } from "react";

const Dropdown = ({ handleSetPrompt, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Store selected item as an object

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const handleSelect = (item) => {
    setSelectedItem(item); // Store the selected item
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="tw-relative tw-inline-block tw-w-full tw-text-left">
      <div>
        <button
          onClick={toggleDropdown}
          className="tw-inline-flex tw-justify-between tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
        >
          {selectedItem ? selectedItem.title : "Select an option"}
          <svg
            className="tw-ml-2 tw-h-5 tw-w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="tw-absolute tw-z-10 tw-mt-2 tw-w-full tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
          <div
            className="tw-py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {items.map((item) => (
              <button
                key={item.id}
                onClick={(e) => {
                  handleSelect(item);
                  handleSetPrompt(e, item.id);
                }}
                className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                role="menuitem"
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
