import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css"; // Importing stylesheet for the Login component
import bg from "../../../../images/login/BG.png"; // Background image for the login screen
import PageTitle from "../../shared/PageTitle/PageTitle"; // Importing a component to set the page title
import ReactGA from "react-ga4"; // Google Analytics for tracking events
import mixpanel from "mixpanel-browser"; // Mixpanel for tracking user interactions
import SliderComponent from "../SliderComponent/SliderComponent"; // Importing a slider component for displaying images
import successStudent from "../../../../images/login/successStudent.png"; // Successful login image
import image1 from "../../../../images/HomePageImage/success/16x9a.jpg"; // Image for the slider
import image2 from "../../../../images/HomePageImage/success/9x16a.jpg"; // Image for the slider
import image3 from "../../../../images/HomePageImage/success/1a.jpg"; // Image for the slider
import i18next from "i18next"; // Internationalization library for translations
import { useTranslation } from "react-i18next"; // Hook for using translations

const Login = () => {
  const { t } = useTranslation(); // Hook for translation
  const [language, setLanguage] = useState("bn"); // State to manage the language
  const [btnLoading, setBtnLoading] = useState(false); // State to manage loading state of the button
  const [inputValue, setInputValue] = useState(""); // State to manage input field value
  const [isValidInput, setIsValidInput] = useState(false); // State to manage if input is valid

  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook for accessing location
  const memoizedData = useMemo(() => {
    return location.state?.from; // Memoizing location state for potential use
  }, [location.state?.from]);

  // Function to validate input (phone/email)
  const validateInput = (value) => {
    const phoneRegex = /^01[3-9]\d{8}$/; // Regex for Bangladesh phone numbers
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation

    // Check if input matches phone or email format
    if (phoneRegex.test(value) || emailRegex.test(value)) {
      setIsValidInput(true); // Set input as valid
    } else {
      setIsValidInput(false); // Set input as invalid
    }
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const value = e.target.value; // Get the current input value
    setInputValue(value); // Update input value state
    validateInput(value); // Validate the input
  };

  // Function to handle the login form submission
  const handelLogIn = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Tracking login event with Google Analytics
    ReactGA.event({
      category: "login",
      action: "login-action",
      label: "login-level",
      value: parseInt(event.target.mobile_or_email.value), // Capture input value
    });

    // Tracking login event with Mixpanel
    mixpanel.track("Login", {
      number: parseInt(event.target.mobile_or_email.value), // Capture the input value
    });

    setBtnLoading(true); // Set loading button state to true
    const login_info = {
      mobile_or_email: event.target.mobile_or_email.value, // Prepare login info
    };

    // Sending login request
    fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}login`, {
      method: "POST", // Set method to POST
      headers: {
        "content-type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify(login_info), // Stringify the login info to send
    })
      .then((res) => res.json()) // Parse JSON response
      .then((data) => {
        // Check if login was successful
        if (data.message_type === "success") {
          // Navigate to OTP page with data
          navigate("/otp?page=login", {
            state: {
              mobile_or_email: event.target.mobile_or_email.value,
              user_id: data.data.user_id,
              message: data.message,
              from: "login",
            },
          });
          setBtnLoading(false); // Reset button loading state
        } else {
          toast.error(data.message); // Show error message
          setBtnLoading(false); // Reset button loading state
        }
      });
  };

  // Language switcher effect
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage"); // Get language from session storage
    if (!useLanguage) {
      // If no language is set, default to 'bn' (Bengali)
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn"); // Change language in i18next
      setLanguage("bn"); // Set language state
    } else {
      // If a language is set, use it
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <section className="tw-h-screen tw-flex tw-items-center tw-justify-center -tw-mt-[88px]">
      <PageTitle title="Login"></PageTitle>
      <div className="md:tw-flex tw-w-full tw-h-full tw-items-center">
        {/* Left side  */}
        <div className="tw-w-full md:tw-w-1/2 tw-h-full tw-flex tw-flex-col tw-justify-center">
          <div className="tw-w-[90%] md:tw-w-[50%] tw-mx-auto">
            <h2 className="tw-text-2xl tw-font-bold">
              {t("Log in with your email or phone number")}
            </h2>
            <p className="tw-mt-2 tw-text-gray-600">
              <Link to="/sign-up">
                <span className=" tw-text-[#6F39B7]">
                  {t("Don’t have an account")}
                  <span className=" tw-border-b tw-border-blue-500 tw-text-blue-500 tw-font-semibold">
                    {t("Register Now")}
                  </span>
                </span>
              </Link>
            </p>
            <form onSubmit={handelLogIn} className="tw-mt-8">
              <label
                htmlFor="mobile_or_email"
                className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700"
              >
                {t("Email/Phone number")}
              </label>
              <input
                id="mobile_or_email"
                name="mobile_or_email"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={t("Email/Phone number")}
                className="tw-block tw-w-full tw-mt-1 tw-rounded-md tw-border-0 tw-pl-4 tw-py-3 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-orange-200 tw-sm:tw-text-sm tw-sm:tw-leading-6"
              />

              <button
                type="submit"
                className={`tw-mt-6 tw-w-full tw-py-3 tw-rounded-3xl tw-text-white ${
                  !isValidInput
                    ? "tw-cursor-not-allowed tw-bg-[#D2BEF4]"
                    : "tw-bg-[#532D80]"
                }`}
                disabled={!isValidInput || btnLoading}
              >
                {t("Submit")}
              </button>
            </form>
          </div>
        </div>

        {/* Right Side  */}
        <div className="tw-hidden md:tw-w-1/2 tw-h-full tw-relative md:tw-block">
          <img
            className="tw-w-full tw-h-full tw-object-cover"
            src={bg}
            alt="background image"
          />

          <div className=" tw-absolute tw-top-[30%] tw-left-[10%] tw-w-[80%] tw-mx-auto  tw-bg-white tw-p-8 tw-rounded-3xl">
            <div className="tw-flex tw-justify-between ">
              <div>
                <img
                  className="tw-w-[350px] tw-h-full tw-rounded-3xl"
                  src={image1}
                  alt="icon"
                />
              </div>
              <div className="md:tw-hidden lg:tw-block">
                <img
                  className="tw-w-[100px] tw-h-full tw-rounded-3xl"
                  src={image2}
                  alt="icon"
                />
              </div>
              <div className="md:tw-hidden lg:tw-block">
                <img
                  className="tw-w-[210px] tw-rounded-3xl"
                  src={image3}
                  alt="icon"
                />
              </div>
            </div>
            <div className="tw-overflow-hidden tw-mt-8">
              <SliderComponent />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
