import React from "react";

function TeacherCard() {
  return (
    <div>
      <div className=" tw-flex tw-flex-col tw-rounded-xl tw-bg-slate-50">
        <img
          src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?t=st=1734085687~exp=1734089287~hmac=16266cf6720ff70f5228add831f8b152352e4919ffceacc7fe9035e5f84b9aa5&w=1380"
          alt="Teacher image"
          className=" tw-max-h-[400px] tw-w-auto  tw-rounded-t-xl"
        />
        <p className=" tw-text-lg tw-mt-4 tw-my-2">মেহফুজ জহির শিশির</p>
        <p>Abacus Mind Math</p>
      </div>
    </div>
  );
}

export default TeacherCard;
